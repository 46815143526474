code {
    font-family:
        source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
    margin: 10px;
}

/*button :hover{*/
/*    color: rgba(130, 250, 115, 1);*/
/*    transition-duration: .5s;*/
/*}*/

.main-content {
    padding-top: 60px;
}

a {
    text-decoration: none;
    color: inherit;
}

div {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
}
#root {
    display: flex;
    flex: 1;
    color: white;
    flex-direction: column;
}

#bevy-canvas {
}

.label-error {
    color: red;
    margin: 5px;
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    z-index: 1000;
}

.header-container .filler {
    flex-grow: 1;
}
.textContainer {
    color: black;
    text-align: justify;
    background: rgba(
        255,
        255,
        255,
        0.8
    ); /* Adjust the opacity and color as needed */
    padding: 20px; /* Adjust padding as needed */
    margin: 20px; /* Adjust margin as needed */
    border-radius: 10px; /* Optional: Add rounded corners */
}
.bodyContainer {
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.formContainer {
    padding: 10% 10%;
    background-color: aliceblue;
    margin: 20vw;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    height: 70vh;
    min-height: fit-content;
    min-width: fit-content;
}
.inputLabelContainer {
    color: black;
    padding: 12px;
    width: 100%;
    margin: 5px 0;
}

.inputLabelContainer input {
    align-items: flex-end;
}
.inputLabelContainer label {
    display: flexbox;
    align-items: flex-start;
    margin-bottom: 5px;
}

.formContainer textarea {
    width: 50vw;
    height: 50px;
    padding: 12px 20px;
    box-sizing: border-box;
    resize: none;
}

.mainContainer {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    margin-top: 100vh;
}
.mainContainer p {
    color: black;
}

.articleContent {
    color: black;
    background-color: rgba(170, 190, 255, 0.8);
    padding: 20px;
    border-radius: 10px; /* Optional: Add rounded corners */
    text-align: justify;
    width: 80%;
}
/* Article container style */
.articleContainer {
    color: black;
    text-align: justify;
    background: rgba(
        255,
        255,
        255,
        0.8
    ); /* Adjust the opacity and color as needed */
    padding: 20px; /* Adjust padding as needed */
    margin: 20px; /* Adjust margin as needed */
    border-radius: 10px; /* Optional: Add rounded corners */
    position: relative;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%; /* Adjust the width as needed */
    z-index: -1;
}

.listArticleContainer {
    margin-top: 10px;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* Optional: Add styles for the text inside the article container */
.articleContainer p {
    color: #333; /* Set text color */
    font-size: 16px; /* Set font size */
    line-height: 1.5; /* Set line height */
}

.loginContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    background-color: rgba(70, 130, 180, 0.6);
    align-items: center;
    width: 555px;
    margin-left: 36vw;
    margin-top: 20vw;

    justify-content: center;
    border-radius: 20px;
    text-align: center;
}
.loginContainer a {
    color: black;
}

.loginContainer input {
    color: white;
    background-color: rgba(70, 130, 180, 0.4);
    transition-duration: 1s;
}
.loginContainer input:click {
    background-color: rgba(250, 250, 255, 1);
}
.loginContainer input:hover {
    color: rebeccapurple;
    background-color: rgba(200, 240, 215, 1);
}
.loginContainer button:hover {
    background-color: rgba(130, 250, 115, 1);
}
.loginContainer a:hover {
    text-decoration: underline;
}
.loginContainer a {
    color: white;
}
.inputBox {
    height: 48px;
    width: 400px;
    font-size: large;
    border-radius: 8px;
    border: 1px solid grey;
    padding-left: 8px;
}

.success {
    display: block;
    color: green;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fefefe;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1;
}
